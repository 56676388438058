import GridLineBox from 'components/GridLineBox'
import { NoData } from 'components/NoData'
import TextFigkCard from 'components/TextFigkCard'
import { Grid } from 'layout/Grid'
import { TextFigkModel } from 'model/home'
import { SearchFigkResponseModel } from 'model/search'
import { MotionVariants } from 'pages/ArtFigk/motion'
import { useOutletContext } from 'react-router-dom'

const SearchTextFigk = () => {
    const { summary, textFigk } = useOutletContext<Pick<SearchFigkResponseModel, 'summary' | 'textFigk'>>()

    return (
        <>
            {textFigk.length > 0 ? (
                <Grid.Common.Container
                    variants={MotionVariants.Container}
                    initial='start'
                    animate='end'>
                    {textFigk.map((t: TextFigkModel) => {
                        return (
                            <GridLineBox
                                key={t.id}
                                column='span 1'>
                                <TextFigkCard
                                    data={t}
                                    word={summary.searchWord}
                                    variants={MotionVariants.list}
                                />
                            </GridLineBox>
                        )
                    })}
                </Grid.Common.Container>
            ) : (
                <NoData.Search>일치하는 결과가 없습니다.</NoData.Search>
            )}
        </>
    )
}

export default SearchTextFigk
