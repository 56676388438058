import axios from 'axios'

const BASE_URL = 'https://api.fig-zone.com/'
// export const BASE_URL = 'http://3.38.254.98/api/'
// export const BASE_URL = 'http://localhost:3100/'
// export const BASE_URL = 'http://192.168.110.233:3100/'

export const baseApi = axios.create({
    baseURL: BASE_URL,
})
