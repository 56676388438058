import { baseApi } from 'api/constant'
import { GenericResponse } from 'model/common'

const figk = {
    getFigkList: async (params: any) => {
        let response = await baseApi.get<GenericResponse>(`figk/text-figk`, { params })
        return response.data
    },
    getFigkDetail: async (params: any) => {
        let response = await baseApi.get<GenericResponse>(`figk/text-figk`, { params })
        return response.data
    },
    increaseTextFigkLike: (id: number): Promise<any> => {
        return baseApi.put<GenericResponse>(`figk-common/like`, { id })
    },
    increaseTextFigkShared: async (id: number) => {
        let response = await baseApi.put<GenericResponse>(`figk-common/shared`, { id, type: 1 })
        return response.data
    },
    increaseTextFigkView: async (id: number) => {
        let response = await baseApi.put<GenericResponse>(`figk-common/view`, { id, type: 1 })
        return response.data
    },
}
export default figk
