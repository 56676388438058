import API from 'api'
import TextFigkList from 'components/TextFigkList'
import useGetInfiniteQuery from 'hook/useInfiniteQuery'
import { useIntersectionObserver } from 'hook/useIntersectionObserver'
import PageTitle from 'layout/PageTitle'
import { returnTextFigkOption } from 'shared/queryOption/figk'

const TextFigk = () => {
    //  text Figk api
    const {
        data: figkList,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetInfiniteQuery(['@getTextFigk'], ({ pageParam = -1 }) => API.Figk.getFigkList({ week: pageParam }), returnTextFigkOption)

    const { setTarget } = useIntersectionObserver({
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    })

    return (
        <>
            <PageTitle title='FIGK' />
            {figkList && <TextFigkList list={figkList} />}
            <div
                style={{ transform: `translate3d(0,-600px,0)` }}
                ref={setTarget}
            />
        </>
    )
}

export default TextFigk
