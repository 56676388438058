import styled from 'styled-components'

const DynamicPlaceholder = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    white-space: nowrap;
    color: ${(p) => p.theme.textColor.textColor10};
`

const MirrorInput = styled.div`
    position: absolute;
    display: inline-block;
    visibility: hidden;
    height: 0;

    font-size: 1.5rem;
    font-weight: 500;
`

const DynamicInput = styled.input`
    ${({ width }) => `width: ${width}px;`}

    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    outline: none;

    background-color: ${(p) => p.theme.bgColor.bg1};
`

const DynamicContent = styled.div`
    position: relative;
    display: flex;
    gap: 4px;
`

export const StyledDynamicInputComponent = {
    DynamicInput,
    MirrorInput,
    DynamicPlaceholder,
    DynamicContent,
}
