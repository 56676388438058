import { ArtFigk } from "./ArtFigk.styled";
import { Common } from "./Common.styled";
import { Home } from "./Home.styled";
import { TextFigk } from "./TextFigk.styled";

export const Grid = {
  Home,
  TextFigk,
  ArtFigk,
  Common,
};
