import styled from 'styled-components'
import { mediaQuery } from 'theme/mediaQuery'
const Container = styled.div`
    position: relative;
    height: 100%;
`

const LineLayer = styled.div`
    position: absolute;
    z-index: 9;
    inset: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    > div {
        border-right: 1px solid ${(p) => p.theme.lineColor.line2};
        &:last-of-type {
            border-right: 0;
        }
    }
    ${mediaQuery('tabletH')} {
        justify-content: center;
        /* grid-template-columns: repeat(2, minmax(142px, 1fr)); */
        grid-template-columns: repeat(2, minmax(142px, 30vw));
        > div {
            height: calc(100% - 1px);
            border-left: 1px solid ${(p) => p.theme.lineColor.line2};
            border-right: 1px solid ${(p) => p.theme.lineColor.line2};

            &:last-of-type {
                border-left: 0;
                border-right: 1px solid ${(p) => p.theme.lineColor.line2};
            }
        }
    }
    ${mediaQuery('mobile')} {
        grid-template-columns: repeat(2, calc(50vw - 16px));
    }
`

const Content = styled.div`
    position: relative;
    z-index: 10;
    height: 100%;
    text-align: center;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    padding-top: max(200px, 10vw);
    span {
        display: inline-block;
        background-color: ${(p) => p.theme.palette.black20};
        color: #fff;
        font-size: 1.4rem;
        margin-bottom: 6rem;
    }

    p {
        font-size: 1.5rem;
        color: ${(p) => p.theme.textColor.textColor70};
        text-align: center;
        line-height: 1.5;
        strong {
            font-weight: 600;
        }
        br {
            &.mobile {
                display: none;
            }
        }
    }
    ${mediaQuery('tabletH')} {
        padding-top: min(100px, 20vw);
    }
    ${mediaQuery('mobile')} {
        padding-top: calc(20vw + 15px);
        padding-inline: ${(p) => p.theme.layout.mobileSidePadding};
        padding-bottom: ${(p) => `calc(${p.theme.layout.mobileHeaderHeight} + 100px + 30px)`};
        p {
            font-size: 1.5rem;
            line-height: 1.6;
            br {
                &.mobile {
                    display: inline;
                }
            }
        }
    }
`

export const S = {
    Container,
    LineLayer,
    Content,
}
