import API from 'api'
import SearchTab from 'components/SearchTab'
import { SearchTabContainer } from 'components/SearchTab/index.styled'
import useGetQuery from 'hook/useQuery'
import PageTitle from 'layout/PageTitle'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTER_PATH } from 'router'
import { returnSearchOption } from 'shared/queryOption/search'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .outletContainer {
        flex: 1;
    }
`

const Search = () => {
    const [searchParams] = useSearchParams()
    const query = searchParams.get('search')
    const navigate = useNavigate()
    const tabHandler = (path: string) => {
        navigate(`${path}?search=${query}`)
    }
    const location = useLocation()
    /** 탭 path 추출 */
    const tabParms = location.pathname.split(`/${ROUTER_PATH.SEARCH}/`)[1]

    const { data: result } = useGetQuery(['@getSearchFigk', String(query)], () => API.Search.getSearchFigk(String(query)), returnSearchOption)

    useEffect(() => {
        if (!query) {
            alert('검색어를 입력해주세요.')
            navigate(-1)
        }
    }, [query])
    return (
        <>
            {result && (
                <Wrapper>
                    <PageTitle>
                        <span className='search_result'>
                            <strong>"{query}"</strong>에 대한 검색결과입니다.
                        </span>
                    </PageTitle>
                    <SearchTabContainer>
                        <div className='contents'>
                            <SearchTab
                                key='FIGK'
                                label='FIGK'
                                totalCount={result?.summary.textFigkTotal || 0}
                                isActive={tabParms === ROUTER_PATH.TEXT_FIGK}
                                onClick={() => tabHandler(ROUTER_PATH.TEXT_FIGK)}
                            />
                            <SearchTab
                                key='ART FIGK'
                                label='ART FIGK'
                                totalCount={result?.summary.artFigkTotal || 0}
                                isActive={tabParms === ROUTER_PATH.ART_FIGK}
                                onClick={() => tabHandler(ROUTER_PATH.ART_FIGK)}
                            />
                        </div>
                    </SearchTabContainer>
                    <div className='outletContainer'>
                        <Outlet context={{ summary: result?.summary, textFigk: result?.textFigk, artFigk: result?.artFigk }} />
                    </div>
                </Wrapper>
            )}
        </>
    )
}

export default Search
