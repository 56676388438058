import Cursor from 'components/Cursor'
import useWidth from 'hook/useWidth'
import Footer from 'layout/GlobalFooter'
import GlobalHeader from 'layout/GlobalHeader'
import GlobalMobileHeader from 'layout/GlobalMobileHeader'
import { Link } from 'react-router-dom'
import { ROUTER_PATH } from 'router'
import { S } from './index.styled'
interface IWrapper {
    children: React.ReactNode
}
const Wrapper = ({ children }: IWrapper) => {
    const { media } = useWidth()
    return (
        <>
            {media.tabletH ? (
                <GlobalMobileHeader />
            ) : (
                <>
                    <GlobalHeader /> <Cursor />
                </>
            )}
            {children}
            <Footer />
        </>
    )
}

const NotFound = () => {
    const { media } = useWidth()
    return (
        <Wrapper>
            <S.Container>
                <S.LineLayer>
                    <div />
                    <div />
                    {!media.tabletH && (
                        <>
                            <div />
                            <div />
                        </>
                    )}
                </S.LineLayer>
                <S.Content>
                    <span className='font_work'>404 ERROR !</span>
                    <h1 className='font_work'>Page not found..</h1>
                    <p>
                        존재하지 않는 주소를 입력하셨거나, <br />
                        요청하신 페이지가 변경 또는 삭제되어 찾을 수 없습니다.
                        <br />
                        입력하신 페이지의 주소가 정확한지 다시 한번 확인해주세요.
                    </p>
                    <Link
                        className='font_work'
                        to={ROUTER_PATH.HOME}>
                        GO HOME
                    </Link>
                </S.Content>
            </S.Container>
        </Wrapper>
    )
}

export default NotFound
