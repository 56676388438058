import errorNumber from 'assets/img/404.png'
import styled from 'styled-components'
import { mediaQuery } from 'theme/mediaQuery'
const Container = styled.div`
    position: relative;
    flex: 1;
    background-image: url(${errorNumber});
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    ${mediaQuery('tabletH')} {
        background-size: 70vw;
    }
    ${mediaQuery('mobile')} {
        background-size: 80vw;
    }
`

const LineLayer = styled.div`
    position: absolute;
    z-index: 9;
    inset: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    > div {
        border-right: 1px solid ${(p) => p.theme.lineColor.line2};
        &:last-of-type {
            border-right: 0;
        }
    }
    ${mediaQuery('tabletH')} {
        justify-content: center;
        /* grid-template-columns: repeat(2, minmax(142px, 1fr)); */
        grid-template-columns: repeat(2, minmax(142px, 30vw));
        > div {
            height: calc(100% - 1px);
            border-left: 1px solid ${(p) => p.theme.lineColor.line2};
            border-right: 1px solid ${(p) => p.theme.lineColor.line2};

            &:last-of-type {
                border-left: 0;
                border-right: 1px solid ${(p) => p.theme.lineColor.line2};
            }
        }
    }
    ${mediaQuery('mobile')} {
        grid-template-columns: repeat(2, calc(50vw - 16px));
    }
`

const Content = styled.div`
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vw;
    span {
        display: inline-block;
        background-color: ${(p) => p.theme.palette.black20};
        color: #fff;
        font-size: 1.4rem;
        margin-bottom: 7vw;
    }
    h1 {
        color: ${(p) => p.theme.palette.black20};
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 3rem;
    }
    p {
        font-size: 1.4rem;
        color: ${(p) => p.theme.palette.gray60};
        text-align: center;
        line-height: 1.5;
        margin-bottom: 5rem;
    }
    a {
        text-align: center;
        width: 130px;
        height: 36px;
        line-height: 36px;
        background-color: ${(p) => p.theme.palette.black20};
        color: #fff;
        font-size: 1.4rem;
        font-weight: 500;
    }
    ${mediaQuery('mobile')} {
        span {
            margin-bottom: 6rem;
        }
        h1 {
            font-size: 2.4rem;
        }
        a {
            width: 160px;
            height: 48px;
            line-height: 48px;
        }
    }
`

export const S = {
    Container,
    LineLayer,
    Content,
}
