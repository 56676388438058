import API from 'api'
import { AxiosError } from 'axios'
import WriterFigkList from 'components/WriterFigkList'
import WriterInfo from 'components/WriterInfo'
import useGetInfiniteQuery from 'hook/useInfiniteQuery'
import { useIntersectionObserver } from 'hook/useIntersectionObserver'
import useGetQuery from 'hook/useQuery'
import { GenericResponse } from 'model/common'
import { FigkParamModel } from 'model/figk'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { returnWriterFigklistOption, returnWriterInfoOption } from 'shared/queryOption/writer'
import styled from 'styled-components'

const HEIGHT = '220px' // .hole 구멍 크기
const WriterContainer = styled.div`
    position: relative;
    background-color: ${(p) => p.theme.bgColor.bg1};
    /* 마우스 오버플로우 효과를 위한 뒷면 */
    .back {
        position: absolute;
        inset: ${(p) => `-${p.theme.layout.headerHeight} 0 0`};
        width: 100%;
        height: ${(p) => `calc(100% + ${p.theme.layout.headerHeight})`};
        background-color: inherit;
        z-index: 0;
    }
    .front {
        position: relative;
        z-index: 1;
        background-color: inherit;
        .footerCover {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
        }
    }
    /* 마우스 커서 효과를 위한 구멍 */
    .hole {
        height: ${HEIGHT};
    }
    @media screen and (max-width: 1200px) {
        .hole {
            height: initial;
        }
    }
`

const LIMIT = 10

const Writer = () => {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const authorId: number = parseInt(params.get('id') || '0')
    const [figkParams, setFigkParams] = useState<FigkParamModel>({ page: 1, per: 20, authorId })

    // 작가 정보 api
    const { data: authorData, error } = useGetQuery(['@getAuthor', String(authorId)], () => API.Writer.getAuthor(authorId), returnWriterInfoOption)

    //  text Figk api
    const {
        data: figkList,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetInfiniteQuery(
        ['@getTextFigk'],
        ({ pageParam = 1 }) => API.Figk.getFigkList({ authorId, page: pageParam, per: LIMIT }),
        returnWriterFigklistOption
    )

    const { setTarget } = useIntersectionObserver({
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    })

    useEffect(() => {
        if (!authorId) {
            alert('잘못된 접근입니다.')
            navigate(-1)
        }
    }, [])

    useEffect(() => {
        if (error) {
            const err = error as AxiosError<GenericResponse>

            alert(err.response?.data.message)
            navigate(-1)
        }
    }, [error])
    return (
        <>
            <WriterContainer>
                {authorData && (
                    <WriterInfo
                        data={authorData}
                        height={HEIGHT}
                    />
                )}

                {figkList && (
                    <WriterFigkList
                        data={figkList}
                        target={setTarget}
                    />
                )}
            </WriterContainer>
        </>
    )
}

export default Writer
