import IconDelete from 'components/Icon/Delete'
import { ChangeEvent, ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react'
import { StyledDynamicInputComponent as S } from './index.styled'

interface Props extends ComponentPropsWithoutRef<`input`> {
    defaultValue: string
    onChangeValue: (value: string) => void
}

const DynamicInput = ({ defaultValue, placeholder = '검색어를 입력해주세요.', onChangeValue, ...rest }: Props) => {
    const [value, setValue] = useState<string>('')
    const [width, setWidth] = useState<number>(0)
    const [isShowPlaceholder, setIsShowPlaceholder] = useState<boolean>(false)

    const mirrorRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const timer = useRef<NodeJS.Timeout>()

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value)
        onChangeValue && onChangeValue(e.currentTarget.value)
    }

    const onReset = () => {
        setValue('')
        onChangeValue && onChangeValue('')
        inputRef.current && inputRef.current.focus()
    }

    useEffect(() => {
        timer.current && clearTimeout(timer.current)

        if (!value) {
            timer.current = setTimeout(() => {
                setIsShowPlaceholder(true)
            }, 50)
        } else {
            setIsShowPlaceholder(false)
            timer.current = undefined
        }

        if (!mirrorRef.current) return

        setWidth(mirrorRef.current?.clientWidth + 2)
    }, [value])

    useEffect(() => {
        setValue(defaultValue || '')

        if (defaultValue) setIsShowPlaceholder(false)
        else setIsShowPlaceholder(true)

        inputRef.current && inputRef.current.focus()
    }, [])

    return (
        <S.DynamicContent>
            <S.DynamicInput
                ref={inputRef}
                width={width}
                value={value}
                onChange={onChange}
                {...rest}
            />
            {value && <IconDelete onClick={onReset} />}
            <S.MirrorInput ref={mirrorRef}>{value}</S.MirrorInput>
            {isShowPlaceholder && (
                <S.DynamicPlaceholder onClick={() => inputRef.current?.focus()}>검색어를 입력해주세요.</S.DynamicPlaceholder>
            )}
        </S.DynamicContent>
    )
}

export default DynamicInput
