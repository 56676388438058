import artFiGk from 'api/artfigk'
import Figk from 'api/figk'
import Home from 'api/home'
import Search from 'api/search'
import Writer from 'api/writer'

const API = {
    Home,
    artFiGk,
    Writer,
    Figk,
    Search,
}

export default API
