import useWidth from 'hook/useWidth'
import { S } from './index.styled'

const About = () => {
    const { media } = useWidth()
    return (
        <S.Container>
            <S.LineLayer>
                <div />
                <div />
                {!media.tabletH && (
                    <>
                        <div />
                        <div />
                    </>
                )}
            </S.LineLayer>
            <S.Content>
                <span>ABOUT FIGK</span>
                <p>
                    매 순간 흐르는 시간 속에서 <br className='mobile' /> 길을 잃지 않기 위해 좌표를 만듭니다. <br /> 표류하지 않기 위해 기록합니다.
                    <br />
                    <br />
                    <strong>FIGK, 오직 TEXT만으로</strong>
                    <br />
                    핔커의 취향과 시선을 드러내는 <br className='mobile' />
                    400자 단문 큐레이션입니다. <br /> <br /> <strong> ART FIGK, 새로운 영감의 원천</strong>
                    <br />
                    아트&컬처 소식을 몰입감 있는 <br className='mobile' />
                    독특한 콘셉트로 선보이는 <br />
                    영상 큐레이션입니다.
                    <br />
                    <br />
                    취향, 그리고 시선을 큐레이션 합니다.
                </p>
            </S.Content>
        </S.Container>
    )
}

export default About
